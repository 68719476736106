<template>
  <div class="sidebar-left">
    <div class="sidebar-left-menu">
      <router-link to="/">
        <div class="btn-click dashboard-btn">
          <img class="sidebar-dashboard-icon d-block" src="/static/images/icon/dashboard.svg">
        </div>
      </router-link>

      <div v-for="page in pages" :key="page">
        <router-link :to="{name: page.name}" class="router-link-btn" v-tippy="{ content: page.describe, theme: 'light' }">
          <div class="btn-click app-btn">
            <img class="sidebar-app-icon" :src="page.icon">
          </div>
        </router-link>
      </div>

    </div>

    <div class="text-center">{{nowTime}}</div>
  </div>
</template>

<script>

export default {
  name: "Sidebar",

  props: ["currentApp"],

  data() {
    return {
      nowTime: '',
      pages: null
    }
  },

  mounted() {
    this.getRouteChild()
    this.nowTimes()
  },

  beforeUnmount() {
    this.clear()
  },

  methods: {
    nowTimes() {
      let hh = new Date().getHours() < 10 ? '0' + new Date().getHours() : new Date().getHours();
      let mm = new Date().getMinutes() < 10 ? '0' + new Date().getMinutes() : new Date().getMinutes();
      hh = hh <= 24 ? hh : hh - 24;
      this.nowTime = hh + ':' + mm;
      setInterval(this.nowTimes, 1000);
      this.clear();
    },

    clear () {
      clearInterval(this.nowTimes);
      this.nowTimes = null;
    },

    getRouteChild() {
      let children = this.$route.matched[0].children
      let pages = []

      children.forEach(function(item) {
        if (item.meta && item.meta.page) {
          pages.push({
            name: item.name,
            icon: item.meta.icon,
            describe: item.meta.name
          })
        }
      })

      this.pages = pages
    }
  }

};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.router-link-active {
  display: block;
  border-radius: 4px;
  background-color: #A5C4FF !important;
}

.router-link-btn {
  display: block;
  border-radius: 4px;
}
.router-link-btn:hover {
  background-color: #727272;
}
</style>
